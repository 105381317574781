@import "~antd/dist/antd.css";

.ant-layout {
  min-height: 100vh;
}

.site-layout-content {
  max-width: 896px;
  margin: 0 auto;
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
.logo {
  float: left;
  height: 31px;
  width: 200px;
  margin: 0 24px 16px 0;
}
.logo h1 {
  color: #fff;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.headder-menu {
  flex: auto;
  justify-content: end;
}
